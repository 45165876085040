









import { Vue, Provide, Component } from "vue-property-decorator";
@Component({})
export default class SignInSuccess extends Vue {
    @Provide() 
    private goLogin(): void {
      this.$router.push('/')
    }
}
